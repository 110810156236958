var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { readonly: _vm.value.id > 0 && !_vm.canEditSetupTables },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "px-0", attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.cancel(false)
                            },
                          },
                        },
                        [_vm._v(" cancel ")]
                      ),
                      _vm.canEditSetupTables
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "float-right",
                              attrs: {
                                color: "green",
                                dark: "",
                                type: "submit",
                              },
                            },
                            [_vm._v(" save ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Type", rules: [_vm.required] },
                            model: {
                              value: _vm.newTripType.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newTripType,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "newTripType.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-0",
                            attrs: {
                              "menu-props": { bottom: true, offsetY: true },
                              label: "Category",
                              items: this.tripCategories,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              rules: [_vm.required],
                            },
                            model: {
                              value: _vm.newTripType.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.newTripType, "categoryId", $$v)
                              },
                              expression: "newTripType.categoryId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Active", rules: [_vm.required] },
                            model: {
                              value: _vm.newTripType.active,
                              callback: function ($$v) {
                                _vm.$set(_vm.newTripType, "active", $$v)
                              },
                              expression: "newTripType.active",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label: "Available on Trip Request Form",
                              rules: [_vm.required],
                              dense: "",
                            },
                            model: {
                              value: _vm.newTripType.availableOnForm,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newTripType,
                                  "availableOnForm",
                                  $$v
                                )
                              },
                              expression: "newTripType.availableOnForm",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label: "Allow Trip Event Rates",
                              rules: [_vm.required],
                              dense: "",
                            },
                            model: {
                              value: _vm.newTripType.allowEventRates,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newTripType,
                                  "allowEventRates",
                                  $$v
                                )
                              },
                              expression: "newTripType.allowEventRates",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Cost Per Mile",
                              rules: [_vm.required, _vm.numeric],
                              type: "number",
                              "prepend-inner-icon": "mdi-currency-usd",
                            },
                            model: {
                              value: _vm.newTripType.costPerMile,
                              callback: function ($$v) {
                                _vm.$set(_vm.newTripType, "costPerMile", $$v)
                              },
                              expression: "newTripType.costPerMile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Planning Cost/Mile",
                              rules: [_vm.required, _vm.numeric],
                              type: "number",
                              "prepend-inner-icon": "mdi-currency-usd",
                            },
                            model: {
                              value: _vm.newTripType.planningCost,
                              callback: function ($$v) {
                                _vm.$set(_vm.newTripType, "planningCost", $$v)
                              },
                              expression: "newTripType.planningCost",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Additional Cost/Mile",
                              rules: [_vm.required, _vm.numeric],
                              type: "number",
                              "prepend-inner-icon": "mdi-currency-usd",
                            },
                            model: {
                              value: _vm.newTripType.additionalCost,
                              callback: function ($$v) {
                                _vm.$set(_vm.newTripType, "additionalCost", $$v)
                              },
                              expression: "newTripType.additionalCost",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Standard Fee",
                              rules: [_vm.required, _vm.numeric],
                              type: "number",
                              "prepend-inner-icon": "mdi-currency-usd",
                            },
                            model: {
                              value: _vm.newTripType.standardFee,
                              callback: function ($$v) {
                                _vm.$set(_vm.newTripType, "standardFee", $$v)
                              },
                              expression: "newTripType.standardFee",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-0",
                            attrs: {
                              "menu-props": { bottom: true, offsetY: true },
                              label: "Vehicle Type(s)",
                              items: _vm.vehicleTypes,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              "hide-details": "",
                              multiple: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-item",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item",
                                      {
                                        attrs: { ripple: "" },
                                        on: {
                                          mousedown: function ($event) {
                                            $event.preventDefault()
                                          },
                                          click: _vm.toggle,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color:
                                                    _vm.selectedVehicleTypes
                                                      ?.length > 0
                                                      ? "primary"
                                                      : "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(_vm.icon) + " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(" Select All "),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider", { staticClass: "mt-2" }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "selection",
                                fn: function ({ item, parent }) {
                                  return [
                                    _c(
                                      "v-chip",
                                      [
                                        _c("span", { staticClass: "pr-2" }, [
                                          _vm._v(" " + _vm._s(item.name) + " "),
                                        ]),
                                        _c(
                                          "v-icon",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return parent.selectItem(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" $delete ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selectedVehicleTypes,
                              callback: function ($$v) {
                                _vm.selectedVehicleTypes = $$v
                              },
                              expression: "selectedVehicleTypes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-select", {
                            staticClass: "mb-0",
                            attrs: {
                              "menu-props": { bottom: true, offsetY: true },
                              label: "Default Vehicle Type",
                              items: _vm.filteredVehicleTypes,
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              "hide-details": "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.newTripType.defaultVehicleTypeId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newTripType,
                                  "defaultVehicleTypeId",
                                  $$v
                                )
                              },
                              expression: "newTripType.defaultVehicleTypeId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }